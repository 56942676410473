import React from "react";
// import emailjs from '@emailjs/browser';
import "../Join/Join.css";

const Join = () => {
  const [result, setResult] = React.useState("");

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending....");
    const formData = new FormData(event.target);

    formData.append("access_key", "cc6e438c-c326-46f3-b732-b25c39bf359d");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData
    });

    const data = await response.json();

    if (data.success) {
      setResult("Sent Successfully");
      event.target.reset();
    } else {
      console.log("Error", data);
      setResult(data.message);
    }
  };


  return (
    <div className="Join" id="Join-us">
      <div className="left-j">
        <hr />
        <div>
          <span className="stroke-text">READY TO</span>
          <span>LEVEL UP</span>
        </div>
        <div>
          <span>YOUR BODY</span>
          <span className="stroke-text">WITH US?</span>
        </div>
      </div>
      <div className="right-j">
        <form  className="email-container" onSubmit={onSubmit}>
          <input type="email" name="user_email" placeholder="Enter your Email address" />
          <button className="btn btn-j">Join Now</button>
          
        </form>
        <span>{result}</span>
      </div>
    </div>
  );
};

export default Join;
