import React, { useState } from "react";
import Logo from "../../assets/logo.png";
import Bars from "../../assets/bars.png";
import { Link } from "react-scroll";

import "./Header.css";

const Header = () => {
  const mobile = window.innerWidth <= 768 ? true : false;
  const [menuOpened, setmemuOpened] = useState(false);
  return (
    <div className="header">
      <img src={Logo} alt="" />
      {menuOpened === false && mobile === true ? (
        <div
          style={{
            backgroundColor: "gray",
            padding: "0.5rem",
            borderRadius: "5px",
          }}
          onClick={() => setmemuOpened(true)}
        >
          <img
            src={Bars}
            alt=""
            style={{ width: "1.5rem", height: "1.5rem" }}
          />
        </div>
      ) : (
        <div className="header-menu">
          <ul>
            <li>
              <Link onClick={() => setmemuOpened(false)} to="Home">
                Home
              </Link>
            </li>
            <li>
              <Link
                onClick={() => setmemuOpened(false)}
                span={true}
                smooth={true}
                to="programs"
              >
                Programs
              </Link>
            </li>
            <li>
              <Link
                onClick={() => setmemuOpened(false)}
                span={true}
                smooth={true}
                to="reasons"
              >
                
                Why us
              </Link>
            </li>
            <li>
              <Link
                onClick={() => setmemuOpened(false)}
                span={true}
                smooth={true}
                to="plans"
              >
                
                Plan
              </Link>
            </li>
            <li>
              <Link
                onClick={() => setmemuOpened(false)}
                span={true}
                smooth={true}
                to="testimonials"
              >
                Testimonials
              </Link>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default Header;
